<template>
    <div class="taskProcess-container">
        <div class="task-process">
            <div class="process-title">工单进展</div>
            <div class="process-container">
                <div @click="() => { $emit('editTabs', -1) }" class="process-item process-line" :class="{'processItem-active': -1 == activeIndex}">
                    <div class="el-icon-check"></div>
                    <div>创建工单</div>
                </div>
                <div v-for="(item, index) in detailInfo.flowList" :key="index" @click="() => { $emit('editTabs', index) }" :style="index == currentIndex && item.rollbackFlag != 1 ? 'border: 1px solid #1890ff': ''" :class="{'processItem-back': item.rollbackFlag == 1,'processItem-no': item.status != 1 && index != currentIndex, 'processItem-active': index == activeIndex, 'process-item': true, 'process-line': (index + 1) != detailInfo.flowList.length}">
                <!-- <div v-for="(item, index) in detailInfo.flowList" :key="index" @click="() => { $emit('editTabs', index) }" :style="index == activeIndex ? 'border: 1px solid #1890ff' : ''" :class="{'process-item': true, 'process-line': (index + 1) != detailInfo.flowList.length}"> -->
                    <div class="el-icon-check" v-if="item.status == 1"></div>
                    <div class="el-icon-close" v-else-if="item.rollbackFlag == 1"></div>
                    <div v-else>{{index + 2}}</div>
                    <div>{{item.taskFlowName}}</div>
                </div>
            </div>
        </div>
        <div v-if="activeIndex == -1" class="detail-container">
            <div class="detail-left" v-if="detailInfo && detailInfo.flowList">
                <div class="detail-title">创建工单</div>
                <div class="detail-item"> <div class="label">创建人</div><div class="item-content">{{ detailInfo.createName || '--'}}</div></div>
                <div class="detail-item"><div class="label">创建时间</div><div class="item-content">{{ detailInfo.createTime ? DateTimeTransform(detailInfo.createTime) : '--'}}</div></div>
            </div>
        </div>
        <div v-if="activeIndex != -1" class="detail-container">
            <div class="detail-left" v-if="detailInfo && detailInfo.flowList">
                <div class="detail-title">任务处理</div>
                <div class="detail-item"> <div class="label">任务名称</div><div class="item-content">{{ detailInfo.flowList[activeIndex].taskFlowName || '--'}}</div></div>
                <div class="detail-item"><div class="label">任务描述</div><div class="item-content">{{ detailInfo.flowList[activeIndex].taskFlowDescribe || '--'}}</div></div>
                <div class="detail-item">
                    <div class="label">附件</div>
                    <div class="item-content">
                        <div v-if="detailInfo.flowList[activeIndex].fileList && detailInfo.flowList[activeIndex].fileList.length" class="fileList-container">
                            <div v-for="(item, index) in detailInfo.flowList[activeIndex].fileList" :key="index" class="fileItem">
                                <div class="fileItem-name">
                                    <common-picture :fileUrl="item.url" :fileType="item.type" :fileName="item.name"></common-picture>
                                </div>
                                <div class="fileItem-btn-Container">
                                    <div class="fileItemBtn" @click="() => {download(item.url)}">下载</div>
                                </div>
                            </div>
                        </div>
                        <div v-if="!detailInfo.flowList[activeIndex].fileList || !detailInfo.flowList[activeIndex].fileList.length" class="fileList-container">
                            <div class="fileItem">
                                暂无附件
                            </div>
                        </div>
                    </div>
                </div>
                <div class="detail-item"><div class="label">处理人</div><div class="item-content">{{detailInfo.flowList[activeIndex].dealName || '--'}}</div></div>
                <div class="detail-item"><div class="label">处理人角色</div><div class="item-content">{{detailInfo.flowList[activeIndex].dealRoleName || '--'}}</div></div>
                <br/>
                <div style="border-top: 1px solid #ccc" v-if="detailInfo.flowList[activeIndex].status == 1">
                    <div class="detail-title">任务完成</div>
                    <div class="detail-item"> <div class="label">完成时间</div><div class="item-content">{{ detailInfo.flowList[activeIndex].finishTime ? DateTimeTransform(detailInfo.flowList[activeIndex].finishTime) : '--'}}</div></div>
                    <div class="detail-item"><div class="label">说明</div><div class="item-content">{{ detailInfo.flowList[activeIndex].finishDescribe || '--'}}</div></div>
                    <div class="detail-item">
                        <div class="label">附件</div>
                        <div class="item-content">
                            <div v-if="detailInfo.flowList[activeIndex].finishFileList && detailInfo.flowList[activeIndex].finishFileList.length" class="fileList-container">
                                <div v-for="(item, index) in detailInfo.flowList[activeIndex].finishFileList" :key="index" class="fileItem">
                                    <div class="fileItem-name">
                                        <common-picture :fileUrl="item.url" :fileType="item.type" :fileName="item.name"></common-picture>
                                    </div>
                                    <div class="fileItem-btn-Container">
                                        <div class="fileItemBtn" @click="() => {download(item.url)}">下载</div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="!detailInfo.flowList[activeIndex].finishFileList || !detailInfo.flowList[activeIndex].finishFileList.length" class="fileList-container">
                                <div class="fileItem">
                                    暂无附件
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="detail-right">
                <!-- <div class="handle-btn" @click="() => {  }">编辑工单</div> -->
                <div class="handle-btn" v-if="detailInfo.status == '1' && detailInfo.flowList[activeIndex].currentFlag == 1 && String(detailInfo.flowList[activeIndex].dealId).split(',').includes(String(userId)) && detailInfo.flowList[activeIndex].rollbackFlag == 1" @click="() => { backEdit(detailInfo.flowList[activeIndex]) }">立即修改</div>
                <div class="handle-btn" v-if="detailInfo.status == '1' && detailInfo.flowList[activeIndex].currentFlag == 1 && String(detailInfo.flowList[activeIndex].dealId).split(',').includes(String(userId)) && detailInfo.flowList[activeIndex].rollbackFlag != 1" @click="() => { completeVisible = true }">任务完成</div>
                <div class="handle-btn-noBg" v-if="detailInfo.status == '1' && detailInfo.flowList[activeIndex].currentFlag == 1 && String(detailInfo.flowList[activeIndex].dealId).split(',').includes(String(userId))" @click="() => { rejectVisible = true }">工单驳回</div>
                <div class="handle-btn" style="background: #afafaf" v-if="detailInfo.flowList[activeIndex].status == 1" @click="() => {  }">已完成</div>
            </div>
        </div>
        <el-dialog v-loading="voidLoading" element-loading-text="拼命加载中..." v-dialogDrag title="任务完成" :append-to-body="true" width="530px" :visible.sync="completeVisible" :close-on-click-modal="false">
            <el-form :model="completeForm" label-width="80px" class="demo-ruleForm">
                <el-form-item label="说明：">
                    <el-input class="common-form-input_100" type="textarea" maxlength="500" show-word-limit :autosize="{ minRows: 6, maxRows: 6 }" placeholder="请输入内容" v-model="completeForm.finishDescribe"></el-input>
                </el-form-item>
                <el-form-item>
                    <div>
                        <div class="common-uploadBnList-container">
                            <div class="fileBtn-container">
                                <div class="fileBtn"> 上传附件 <input class="file-input" type="file" @input="(e) => {uploadFile(e)}"></div>
                                上传文件及图片，大小不超过50MB
                            </div>
                            <br/>
                            <div class="fileList-container">
                                <div v-for="(item, index) in completeFileList" :key="index" class="fileList-item">
                                    <div class="item-name">
                                        <common-picture :fileUrl="item.filedUrl" :fileType="item.filedType" :fileName="item.filedName"></common-picture>
                                    </div>
                                    <div @click="() => {deleteFlie(index)}" class="deleteBtn">删除</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button class="common-screen-btn" @click="completeVisible = false">取 消</el-button>
                <el-button class="common-screen-btn" type="primary" @click="() => { completeTask() }">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog v-loading="voidLoading" element-loading-text="拼命加载中..." v-dialogDrag title="工单驳回" :append-to-body="true" width="530px" :visible.sync="rejectVisible" :close-on-click-modal="false">
            <el-form :model="rejectForm" :rules="rejectRule" ref="rejectForm" label-width="80px" class="demo-ruleForm">
                <el-form-item label="驳回位置" prop="flowId">
                    <el-select class="common-form-input_100" v-model="rejectForm.flowId">
                        <el-option v-for="(item, index) in taskSelect" :key="index" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="说明：">
                    <el-input class="common-form-input_100" type="textarea" maxlength="500" show-word-limit :autosize="{ minRows: 6, maxRows: 6 }" placeholder="请输入内容" v-model="rejectForm.finishDescribe"></el-input>
                </el-form-item>
                <el-form-item>
                    <div>
                        <div class="common-uploadBnList-container">
                            <div class="fileBtn-container">
                                <div class="fileBtn"> 上传附件 <input class="file-input" type="file" @input="(e) => {rejectFile(e)}"></div>
                                上传文件及图片，大小不超过50MB
                            </div>
                            <br/>
                            <div class="fileList-container">
                                <div v-for="(item, index) in rejectFileList" :key="index" class="fileList-item">
                                    <div class="item-name">
                                        <common-picture :fileUrl="item.filedUrl" :fileType="item.filedType" :fileName="item.filedName"></common-picture>
                                    </div>
                                    <div @click="() => {rejectDelFlie(index)}" class="deleteBtn">删除</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button class="common-screen-btn" @click="rejectVisible = false">取 消</el-button>
                <el-button class="common-screen-btn" type="primary" @click="() => { rejectTask() }">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import CommonPicture from "../../../components/common/commonPicture.vue"
import { Config, DateTimeTransform } from "../../../utils/index.js"
import { upload } from "../../../service/upload.js";
import { completeTask, rejectTask } from "../../../service/workOrder.js"
export default {
    components: { CommonPicture },
    props: ["detailInfo", "activeIndex", "currentIndex", "taskSelect"],
    data () {
        return {
            DateTimeTransform,
            voidLoading: false,
            completeVisible: false,
            rejectVisible: false, // 驳回弹窗
            // activeIndex: 1,
            userId: this.$sto.get(Config.constants.userInfo).user_id,
            completeForm: {
                finishDescribe: ''
            },
            completeFileList: [],
            rejectForm: {
                flowId: '', // 驳回步骤id
                finishDescribe: '', // 驳回说明
            },
            rejectRule: {
                flowId: [{ required: true, message: "请选择驳回步骤", trigger: "change" }]
            },
            rejectFileList: [],
        }
    },
    created () {
        // if (this.detailInfo) {
        //     this.detailInfo.flowList.map((item, index) => {
        //         if (item.currentFlag == 1) {
        //             this.activeIndex = index
        //         }
        //     })
        // }
    },
    methods: {
        backEdit (info) {
            this.completeForm = {
                finishDescribe: info.finishDescribe
            }
            let completeFileList = []
            if (info.finishFileList && info.finishFileList.length) {
                info.finishFileList.map((item) => {
                    completeFileList.push({filedUrl: item.url, filedType: item.type, filedName: item.name})
                })
            }
            this.completeFileList = completeFileList
            this.completeVisible = true
        },
        async rejectTask () { // 工单驳回
            this.$refs['rejectForm'].validate(async (valid) => {
                if (valid) {
                    let rejectForm = {...this.rejectForm}
                    let rejectFileList = [...this.rejectFileList]
                    if (rejectFileList && rejectFileList.length) {
                        let finishFileName = []
                        let finishFileUrl = []
                        rejectFileList.map((item) => {
                            finishFileName.push(item.filedName)
                            finishFileUrl.push(item.filedUrl)
                        })
                        rejectForm.finishFileName = finishFileName.join(',')
                        rejectForm.finishFileUrl = finishFileUrl.join(',')
                    }
                    rejectForm.taskId = this.detailInfo.id
                    let { code } = await rejectTask(rejectForm)
                    if ( code == 200 ) {
                        this.$message.success("驳回成功")
                        this.$emit('getTableParams', {id: this.detailInfo.id})
                        this.rejectVisible = false
                        this.rejectForm = {
                            flowId: '',
                            finishDescribe: ''
                        }
                        if (this.$refs["rejectForm"]) this.$refs["rejectForm"].resetFields();
                    }
                } else {
                    return false;
                }
            });
            
        },
        async completeTask () { // 完成任务
            let completeForm = {...this.completeForm}
            let completeFileList = [...this.completeFileList]
            if (completeFileList && completeFileList.length) {
                let finishFileName = []
                let finishFileUrl = []
                completeFileList.map((item) => {
                    finishFileName.push(item.filedName)
                    finishFileUrl.push(item.filedUrl)
                })
                completeForm.finishFileName = finishFileName.join(',')
                completeForm.finishFileUrl = finishFileUrl.join(',')
            }
            completeForm.taskId = this.detailInfo.id
            let { code } = await completeTask(completeForm)
            if ( code == 200 ) {
                this.$message.success("任务完成")
                this.$emit('getTableParams', {id: this.detailInfo.id})
                this.completeVisible = false
                this.completeForm = {
                    finishDescribe: ''
                }
            }
        },
        async deleteFlie (i) {  //  删除文件
            let completeFileList = [...this.completeFileList]
            completeFileList = completeFileList.filter((item, index)=>{
                return i != index
            })
            this.completeFileList = completeFileList
        },
        async rejectDelFlie (i) {  //  删除文件
            let rejectFileList = [...this.rejectFileList]
            rejectFileList = rejectFileList.filter((item, index)=>{
                return i != index
            })
            this.rejectFileList = rejectFileList
        },
        async uploadFile (e) { // 上传文件
            let completeFileList = [...this.completeFileList]
            let file = e.target.files[0];
            let fileName = file.name;
            let format = file.name.split(".")[file.name.split(".").length - 1];
            if (file.size / 1024 > 1024 * 50) {
                e.target.value = "";
                return this.$message.error('文件大小不能超过50M')
            }
            if (!Config.fileType.PWEIType.includes(format)) {
                e.target.value = "";
                return this.$message.error(`只能上传 ${Config.fileMessage.PWEIType} 格式的文件`)
            }
            this.loading = true
            let { data, code } = await upload({ file: file });
            this.loading = false
            if ( code == 200 ) {
                this.$message.success("上传成功")
                completeFileList.push({ filedName: fileName, filedUrl: data.filename, filedId: data.id, filedType: Config.fileType.imgType.includes(format) ? 'imgText' : 'file'})
                this.completeFileList = completeFileList
            }
            e.target.value = ''
        },
        async rejectFile (e) { // 上传文件
            let rejectFileList = [...this.rejectFileList]
            let file = e.target.files[0];
            let fileName = file.name;
            let format = file.name.split(".")[file.name.split(".").length - 1];
            if (file.size / 1024 > 1024 * 50) {
                e.target.value = "";
                return this.$message.error('文件大小不能超过50M')
            }
            if (!Config.fileType.PWEIType.includes(format)) {
                e.target.value = "";
                return this.$message.error(`只能上传 ${Config.fileMessage.PWEIType} 格式的文件`)
            }
            this.loading = true
            let { data, code } = await upload({ file: file });
            this.loading = false
            if ( code == 200 ) {
                this.$message.success("上传成功")
                rejectFileList.push({ filedName: fileName, filedUrl: data.filename, filedId: data.id, filedType: Config.fileType.imgType.includes(format) ? 'imgText' : 'file'})
                this.rejectFileList = rejectFileList
            }
            e.target.value = ''
        },
        download(url) { // 下载
            window.location.href = url
        },
    }
}
</script>
<style lang="less" scoped>
.taskProcess-container {
    background: #fff;
    text-align: left;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 32px;
    box-sizing: border-box;
    .detail-container {
        display: flex;
        .fileList-container {
          .fileItem {
            display: flex;
            justify-content: space-between;
            height: 32px;
            line-height: 32px;
            align-items: center;
            .fileItem-btn-Container {
              display: flex;
              .fileItemBtn {
                color: #1890ff;
                cursor: pointer;
              }
            }
          }
        }
        .detail-left {
            flex: 1;
            overflow-x: auto;
            .detail-item {
                color: #999;
                font-size: 14px;
                display: flex;
                margin-top: 24px;
                .item-content {
                    flex: 1;
                        overflow-x: auto;
                        white-space: pre-line;
                        color: #333;
                        font-size: 14px;
                        margin-left: 16px;
                }
                .label {
                    width: 76px;
                }
            }
            .detail-title {
                font-size: 16px;
                color: #1890ff;
                margin-top: 24px;
            }
        }
        .detail-right {
            width: 160px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .handle-btn {
                width: 120px;
                height: 40px;
                line-height: 41px;
                border-radius: 4px;
                color: #fff;
                background: #1890FF;
                text-align: center;
                font-size: 14px;
                cursor: pointer;
                margin-top: 24px;
            }
            .handle-btn-noBg {
                width: 120px;
                height: 38px;
                line-height: 38px;
                border-radius: 4px;
                border: 1px solid #1890ff;
                color: #1890ff;
                background: #fff;
                text-align: center;
                font-size: 14px;
                cursor: pointer;
                margin-top: 24px;
            }
        }
    }
    .task-process {
        display: flex;
        width: 100%;
        height: 84px;
        
        border-bottom: 1px solid #e6e6e6;
        align-items: center;
        .process-title {
            color: #1890ff;
            font-size: 24px;
            padding-right: 32px;
            padding-bottom: 32px;
        }
        .process-container {
            flex: 1;
            overflow-x: auto;
            display: flex;
            align-items: center;
            padding-bottom: 32px;
            .process-line {
                margin-right: 60px;
                position: relative;
                &::after {
                    position: absolute;
                    display: block;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    right: -61px;
                    content: ' ';
                    height: 1px;
                    width: 60px;
                    background: #e6e6e6;
                }
            }
            .process-item {
                display: flex;
                align-items: center;
                height: 40px;
                background: #1890ff19;
                padding: 0 8px;
                cursor: pointer;
                white-space: nowrap;
                box-sizing: border-box;
                border: 1px solid #fff;
                div {
                    &:nth-of-type(1) {
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        background: #1890ff;
                        color: #fff;
                        margin-right: 8px;
                        text-align: center;
                        line-height: 24px;
                        font-size: 16px;
                    }
                    &:nth-of-type(2) {
                        color: #5c5c5c;
                        font-size: 16px;
                    }
                }
            }
            .processItem-no {
                background: #1890ff19;
                color: #fff;
                div {
                    &:nth-of-type(1) {
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        background: #979797;
                        color: #fff;
                        margin-right: 8px;
                        text-align: center;
                        line-height: 24px;
                        font-size: 16px;
                    }
                    &:nth-of-type(2) {
                        color: #5c5c5c;
                        font-size: 16px;
                    }
                }
            }
            .processItem-active {
                background: #1890ff;
                color: #fff;
                div {
                    &:nth-of-type(1) {
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        background: #fff;
                        color: #1890ff;
                        margin-right: 8px;
                        text-align: center;
                        line-height: 24px;
                        font-size: 16px;
                    }
                    &:nth-of-type(2) {
                        color: #fff;
                        font-size: 16px;
                    }
                }
                .el-icon-check {
                    font-weight: 700;
                }
            }
            .processItem-back {
                background: #FF4D4F;
                color: #fff;
                div {
                    &:nth-of-type(1) {
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        background: #fff;
                        color: #FF4D4F;
                        margin-right: 8px;
                        text-align: center;
                        line-height: 24px;
                        font-size: 16px;
                    }
                    &:nth-of-type(2) {
                        color: #fff;
                        font-size: 16px;
                    }
                }
                .el-icon-check {
                    font-weight: 700;
                }
            }
        }
    }
}
</style>